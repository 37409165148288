@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNDr4fIA9c.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

.css-yk16xz-control {
  border: none !important;
  height: 53px !important;
  font-weight: 500;
  border-radius: 10px !important;
}


.css-1pahdxg-control {
  height: 53px !important;
  border-radius: 10px !important;
  font-weight: 500;
}

.select-custom {
  border: 2px solid #B8CAE8;
  border-radius: 10px;
  margin-top: 20px;
  // height: 53px;
}

.account__container {
  display: block;
  margin: 0 auto;
  background: white;
  border-radius: 1em;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 4em;
  width: 75%;
}

.error-msg {
  color: red !important;
  display: flex;
  gap: 5px;
  font-size: 12px;
}

.success-msg {
  color: #11af41;
  display: flex;
  gap: 5px;
  font-size: 12px;
}

.button__container {
  margin-top: 2rem;
}

.hidden {
  display: none;
}

.password-container {
  display: block;
}

.react-datepicker__input-container {
  height: 50px;

  > input {
    cursor: pointer;
    border: 1px solid #b8cae8;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 0 15px 0 15px;
  }
}

.display-none {
  display: none;
}

.validation {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  flex-direction: row;
  justify-content: left;
  
  // margin-top: 15px !important;
}

.validation-item {
  margin-right: 8px;
  width: 100%;
  align-self: center;
  white-space: nowrap;
}

.validation-icon {
  display: inline-block;
  margin-right: 0.5em;
  width: 1.25em;
}

.validator {
  display: grid;
  align-items: center;
}

.error {
  @extend .validation-icon;
  color: #f2180a;
}

.success {
  @extend .validation-icon;
  color: #11af41;
}

.link-active {
  > a {
    a:active,
    :focus {
      outline: none;
      color: black;
    }
  }

  > div:active,
  div:focus {
    color: black;
    font-weight: 600;
  }
  a:active {
    color: black;
    font-weight: 600;
  }
}

.img-btn:hover {
  cursor: pointer;
}

.chakra-radio {
  border-radius: 20px;
  background: #e5e5e5;
  padding: 4px;
  padding-right: 12px;
  padding-left: 10px;
  width: fit-content;
}

.chakra-radio[data-checked] {
  background-color: #f2180a;
}

.chakra-radio__label {
  color: #292b30;
  font-size: 14px !important;
}

.chakra-radio__label[data-checked] {
  color: white;
}

.chakra-radio__control {
  border: 1px solid !important;
  border-color: #5c6066 !important;
}

.chakra-radio__control[data-checked] {
  background-color: white !important;
  border-color: white !important;
}

.remove-margin {
  > .chakra-form-control,
  .chakra-form__label {
    margin-bottom: 0px;
  }
  input[type="radio"] {
    background-color: transparent;
    cursor: pointer;
  }
}

input[type="radio"]:checked {
  background-color: white;
}
